.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.b-example-divider {
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
  cursor: pointer;
}

.menu_collapse_icon{
  margin-top: -5px;
  margin-left: 3px;
  position: relative;
  top: 50%;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

#top_menu_titel .bi{
  font-size: 2rem;
  margin-right: 10px;
}

#file_open button{
  width:100%;
}

.scrollarea {
  overflow-y: auto;
}
